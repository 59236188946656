/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2024
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 **/

/**
 * To enable code-splitting and dynamic loading of the application (a hard
 * requirement when Tooling is loaded from within watsonx Orchestrate as
 * a Micro Front-End, or MFE), we asynchronously load a module that then
 * bootstraps the entire application.
 */

import('./bootstrap.tsx');
